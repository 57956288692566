<template>
    <InspirationGallery v-editable="blok" :data="inspirationGalleryContent" />
</template>

<script setup lang="ts">
import InspirationGalleryType from '@/types/InspirationGalleryType';
import InspirationItemType from '@/types/InspirationItemType';
import ImageType from '@/types/ImageType';
import InspirationGallery from '~/components/page-building/inspiration-gallery/InspirationGallery.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const inspirationItems = props.blok.inspiration_items
    ? props.blok.inspiration_items.map((item: any) => {
          return {
              title: item.title,
              image: {
                  title: item.image.name,
                  alt: item.image.alt,
                  src: item.image.filename,
              } as ImageType,
              productCategory: item.product_category,
              industry: item.industry,
              material: item.material,
              designStyle: item.design_style,
              propertyAndEffect: item.property_and_effect,
          } as InspirationItemType;
      })
    : [];

const inspirationGalleryContent = computed<InspirationGalleryType>(() => {
    return {
        productCategoryFilters: props.blok.product_category_filters
            ? props.blok.product_category_filters.split('\n')
            : [],
        industryFilters: props.blok.industry_filters
            ? props.blok.industry_filters.split('\n')
            : [],
        materialFilters: props.blok.material_filters
            ? props.blok.material_filters.split('\n')
            : [],
        designStyleFilters: props.blok.design_style_filters
            ? props.blok.design_style_filters.split('\n')
            : [],
        propertyAndEffectFilters: props.blok.property_and_effect_filters
            ? props.blok.property_and_effect_filters.split('\n')
            : [],
        inspirationItems,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
